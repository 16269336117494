import { Image } from "react-grid-gallery";
import img3 from "../../assets/images/gallery/1.jpeg";
import img11 from "../../assets/images/gallery/10.jpg";
import img7 from "../../assets/images/gallery/11.jpg";
import img12 from "../../assets/images/gallery/12.jpg";
import img13 from "../../assets/images/gallery/13.jpg";
import img14 from "../../assets/images/gallery/14.jpeg";
import img15 from "../../assets/images/gallery/15.jpg";
import img16 from "../../assets/images/gallery/16.jpg";
import img17 from "../../assets/images/gallery/17.jpg";
import img18 from "../../assets/images/gallery/18.jpg";
import img19 from "../../assets/images/gallery/19.jpg";
import img10 from "../../assets/images/gallery/2.jpg";
import img2 from "../../assets/images/gallery/3.jpg";
import img4 from "../../assets/images/gallery/4.jpg";
import img5 from "../../assets/images/gallery/5.jpg";
import img6 from "../../assets/images/gallery/6.jpg";
import img1 from "../../assets/images/gallery/7.jpg";
import img8 from "../../assets/images/gallery/8.jpeg";
import img9 from "../../assets/images/gallery/9.jpeg";

export interface CustomImage extends Image {
	original: string;
}

export const images: CustomImage[] = [
	{
		src: img1,
		original: img1,
		width: 500,
		height: 500,
		tags: [],
		caption: "",
	},
	{
		src: img2,
		original: img2,
		width: 500,
		height: 500,
		tags: [],
		caption: "",
	},
	{
		src: img3,
		original: img3,
		width: 500,
		height: 500,
		tags: [],
		caption: "",
	},
	{
		src: img4,
		original: img4,
		width: 500,
		height: 500,
		tags: [],
		caption: "",
	},
	{
		src: img5,
		original: img5,
		width: 500,
		height: 500,
		tags: [],
		caption: "",
	},
	{
		src: img6,
		original: img6,
		width: 500,
		height: 500,
		tags: [],
		caption: "",
	},
	{
		src: img7,
		original: img7,
		width: 500,
		height: 500,
		tags: [],
		caption: "",
	},
	{
		src: img8,
		original: img8,
		width: 500,
		height: 500,
		tags: [],
		caption: "",
	},
	{
		src: img9,
		original: img9,
		width: 500,
		height: 500,
		tags: [],
		caption: "",
	},
	{
		src: img10,
		original: img10,
		width: 500,
		height: 500,
		tags: [],
		caption: "",
	},
	{
		src: img11,
		original: img11,
		width: 500,
		height: 500,
		tags: [],
		caption: "",
	},
	{
		src: img12,
		original: img12,
		width: 500,
		height: 500,
		tags: [],
		caption: "",
	},
	{
		src: img13,
		original: img13,
		width: 500,
		height: 500,
		tags: [],
		caption: "",
	},
	{
		src: img14,
		original: img14,
		width: 500,
		height: 500,
		tags: [],
		caption: "",
	},
	{
		src: img15,
		original: img15,
		width: 500,
		height: 500,
		tags: [],
		caption: "",
	},
	{
		src: img19,
		original: img19,
		width: 500,
		height: 500,
		tags: [],
		caption: "",
	},
	{
		src: img16,
		original: img16,
		width: 500,
		height: 500,
		tags: [],
		caption: "",
	},
	{
		src: img17,
		original: img17,
		width: 500,
		height: 500,
		tags: [],
		caption: "",
	},
	{
		src: img18,
		original: img18,
		width: 500,
		height: 500,
		tags: [],
		caption: "",
	},
];
